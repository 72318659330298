/*
* ink colors (with variations only for black and white)
*/
.login {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: relative;
  align-items: center;
  justify-content: center; }

.login__modal {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: fixed;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  padding: calc(3.25 * 1rem) 0;
  z-index: 99999; }

.div, .top, .bottom {
  display: flex;
  position: absolute;
  flex: 1;
  width: 100%; }

.top {
  height: 275px;
  top: 0;
  background-color: #1D66DD; }
  @media (max-width: 768px) {
    .top {
      height: 174px; } }

.bottom {
  bottom: 0; }

.center {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 30px; }

.form {
  margin-top: 34px;
  width: 328px;
  padding: 24px 16px 16px;
  border-radius: 4px;
  box-shadow: 0 1px 4px 0 rgba(51, 51, 51, 0.16);
  background-color: #ffffff; }
  .form input {
    padding: 0 46px !important;
    width: 100%;
    border-radius: 4px;
    border: solid 1px #707070;
    font-size: 16px !important; }
    .form input::placeholder {
      color: #333333; }
  @media (min-width: 768px) {
    .form {
      width: 420px; } }

.popup--modal {
  position: relative;
  margin-top: 34px;
  width: 360px;
  border-radius: 8px 8px 0px 0px;
  box-shadow: 0 1px 4px 0 rgba(51, 51, 51, 0.16);
  background: linear-gradient(180deg, #4380E3 0%, #1D66DD 48.5%, #1855B8 100%);
  padding: unset; }
  .popup--modal input {
    padding: 0 calc(0.75 * 1rem) !important;
    width: 100%;
    border-radius: 4px;
    border: solid 1px #707070;
    font-size: 16px !important; }
    .popup--modal input::placeholder {
      color: #333333; }
  .popup--modal input[type="tel"] {
    padding: 0 45px !important; }

.login-content {
  padding: 24px 16px 16px;
  gap: 12px;
  border-radius: 8px 8px 0px 0px;
  background: white; }

.or {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 100%; }
  .or span {
    color: #333333; }
  .or div:first-child {
    width: 25%; }
  .or div:last-child {
    width: 25%; }

.divider {
  height: 1px;
  width: 100%;
  border-top: solid 1px #707070; }

.cheetah-logo {
  position: absolute;
  top: -32px;
  right: 16px;
  z-index: 2; }

.regis__text {
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  text-align: center;
  padding: 16px 0 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: center; }
  .regis__text a {
    color: #1D66DD;
    font-weight: bold;
    text-decoration: none; }

.action-btn, .redirect-btn {
  width: 100%;
  padding: 11px;
  color: #ffffff;
  border-radius: 4px;
  background-color: #ea4b4b;
  border: none;
  cursor: pointer; }

.redirect-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  color: #1D66DD;
  font-weight: 700;
  border: 0.5px solid #1D66DD; }
  .redirect-btn img {
    max-width: 20px; }

.button-disabled {
  border-color: rgba(0, 0, 0, 0.09);
  color: rgba(0, 0, 0, 0.26);
  opacity: 0.5; }

.button-action-disabled {
  border-color: rgba(0, 0, 0, 0.09);
  opacity: 0.5; }

.input-password {
  display: flex;
  position: relative;
  align-items: center;
  width: 100%; }
  .input-password div {
    width: 100%; }
  .input-password .action-password {
    display: flex;
    position: absolute;
    right: 10px;
    cursor: pointer; }

.top-5p {
  margin-top: 5px; }

.bottom-18p {
  margin-bottom: 18px; }

.bottom-16p {
  margin-bottom: 16px; }

.bottom-24p {
  margin-bottom: 24px; }

.bottom-13p {
  margin-bottom: 13px; }

.bottom-8p {
  margin-bottom: 8px; }

.right-5p {
  margin-right: 5px; }

.forgot-password {
  font-size: 12px;
  color: #1D66DD; }
  .forgot-password span {
    cursor: pointer; }

.register-now {
  font-size: 14px; }
  .register-now span:first-child {
    color: #033333; }
  .register-now span:last-child {
    color: #1D66DD;
    cursor: pointer; }

.verification {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center; }
  .verification span {
    color: #333; }
    .verification span:first-child {
      font-size: 20px;
      margin-bottom: 16px; }
    .verification span:nth-child(2) {
      margin-bottom: 4px; }

.otp-verification {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
  justify-content: center; }
  .otp-verification input {
    border-radius: 50px;
    border: none;
    outline: none;
    width: 40px;
    height: 40px;
    background-color: #f2f7ff;
    margin: 0 8px 8px 0;
    text-align: center; }
    .otp-verification input:last-child {
      margin-right: 0px; }

.input-error span {
  color: #E81A1A !important; }

.input-error input {
  border: 1px solid #E81A1A !important;
  margin: 0;
  outline: none;
  color: #E81A1A !important; }
  .input-error input::placeholder {
    color: #E81A1A !important; }

.text-error {
  color: #E81A1A; }

.text-disabled {
  color: #707070 !important; }

.text-center {
  text-align: center; }

.text-right {
  text-align: right; }

.hidden {
  display: none; }

.d-flex {
  display: flex; }

.go_to_regis {
  cursor: pointer;
  border: 0.5px solid #1D66DD;
  padding: 4px 6px 4px 6px;
  gap: 4px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: bold; }

.login-back-btn {
  color: #1D66DD;
  font-size: 12px;
  font-weight: bold;
  padding: 4px 6px 4px 6px;
  gap: 4px;
  border-radius: 4px;
  border: 0.5px solid #1D66DD;
  width: 90px;
  margin-bottom: 16px;
  cursor: pointer; }
  .login-back-btn img {
    margin: 0px 5px;
    height: 9.33px;
    width: 4.67px;
    vertical-align: middle; }

.w-full {
  width: 100%; }
